import React from 'react'
import styled from 'styled-components'
import { Row, Section } from '../Layout'
import { FadeOnSlide } from '../Shared'
import { getOutlink } from '../../utils/analyticsUtils'

const Label = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0.05em;
  text-align: left;
  margin: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const StyledButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
  padding: 8px 16px;
  min-width: 104px;
  height: 32px;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.25s ease-in;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

const StyledSubTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  text-align: center;
  max-width: 900px;
`

const DiscoverSlice = ({ data }) => (
  <Section bgStyle="dark">
    <FadeOnSlide>
      <Row>
        <h2>{data.discoverTitle}</h2>
      </Row>
      <Row alignItems="center" justifyContent="center">
        <StyledSubTitle>{data.discoverSubtitle}</StyledSubTitle>
      </Row>
      <Row alignItems="stretch" justifyContent="center">
        <StyledButton href={getOutlink()} rel="noreferrer">
          <Label>{data.discoverButtonLabel}</Label>
        </StyledButton>
      </Row>
    </FadeOnSlide>
  </Section>
)

export default DiscoverSlice
