import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Section } from '../Layout'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  ${media.lg`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const Item = styled.div`
  max-width: 600px;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.secondary.main};
  margin: 40px 0;
`

const ParagraphTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const Paragraph = styled.p``

const WhyChooseUsSlice = ({ data }) => (
  <Section bgStyle="dark" showTopWave>
    <Title>{data.whyChooseUsTitle}</Title>
    <Grid>
      <Item>
        <ParagraphTitle>{data.reasonTitle1}</ParagraphTitle>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: data.reasonParagraph1?.childMarkdownRemark?.html,
          }}
        />
      </Item>
      <Item>
        <ParagraphTitle>{data.reasonTitle2}</ParagraphTitle>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: data.reasonParagraph2?.childMarkdownRemark?.html,
          }}
        />
      </Item>
      <Item>
        <ParagraphTitle>{data.reasonTitle3}</ParagraphTitle>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: data.reasonParagraph3?.childMarkdownRemark?.html,
          }}
        />
      </Item>
    </Grid>
  </Section>
)

export default WhyChooseUsSlice
