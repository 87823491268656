import React from 'react'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import { Layout, Seo } from '../components/Layout'
import CaseStudyHeaderSlice from '../components/CaseStudies/CaseStudyHeaderSlice'
import CaseStudyNumberCardsSlice from '../components/CaseStudies/CaseStudyNumbercardsSlice'
import CaseStudyMainSlice from '../components/CaseStudies/CaseStudyMainSlice'
import WhyChooseUsSlice from '../components/Slices/WhyChooseUsSlice'
import TestimonialSlice from '../components/Slices/TestimonialSlice'
import DiscoverSlice from '../components/Slices/DiscoverSlice'

deckDeckGoHighlightElement()

const CaseStudyPage = ({ pageContext: { page: pageData } }) => {
  const {
    slug,
    shortDescription,
    clientImage,
    title,
    numberBox1,
    numberBox2,
    numberBox3,
    numberBox4,
    subTitle,
  } = pageData
  const pathname = `case-studies/${slug}`
  const breadcrumbList = [
    { position: 1, name: 'Case Studies', path: 'case-studies' },
    { position: 2, name: title, path: pathname },
  ]

  return (
    <Layout noSignupSlice>
      <Seo
        title={title}
        heroImage={clientImage}
        pathname={pathname}
        description={shortDescription}
        breadcrumbList={breadcrumbList}
      />
      <CaseStudyHeaderSlice
        title={shortDescription}
        subTitle={subTitle}
        data={pageData}
      />
      <CaseStudyNumberCardsSlice
        data={{
          numberBox1,
          numberBox2,
          numberBox3,
          numberBox4,
        }}
      />
      <CaseStudyMainSlice data={pageData} />
      <WhyChooseUsSlice data={pageData} />
      <TestimonialSlice data={pageData} />
      <DiscoverSlice data={pageData} />
    </Layout>
  )
}

export default CaseStudyPage
