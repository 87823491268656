/* eslint-disable import/no-unresolved */
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { media } from 'styled-bootstrap-grid'
import styled, { useTheme } from 'styled-components'
import ClientIcon from 'images/client-icon.svg'
import ChallengeIcon from 'images/challenge-icon.svg'
import SolutionIcon from 'images/solution-icon.svg'
import CustomizedTemplateIcon from 'images/customized-template-icon.svg'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1400px;
  margin: auto;
  ${media.lg`
    grid-template-columns: 1fr 1fr;
  `}
`

const Template = styled.div`
  display: flex;
  justify-content: center;
`

const Item = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  display: grid;
  justify-items: center;
`

const Box = styled.div`
  width: calc(100% - 48px);
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 64px;
  ${media.lg`
    width: calc(100% - 64px);
    max-width: 80%;
  `}
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 20px;
  max-width: 160px;
  ${media.lg`
    margin-bottom: 40px;
    max-width: 280px;
  `}
`

const Text = styled.div`
  font-size: 25px;
  line-height: 1.5;
  text-align: center;
`

const QuoteText = styled.div`
  font-size: 32px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: center;
  color: ${({ darkQuote }) => darkQuote && 'white'};
`

const QuoteSource = styled.p`
  font-size: 20px;
  color: #ffd301;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const SubTitle = styled.p`
  margin-top: 0;
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 28px;
`

const CaseStudyMainSlice = ({ data }) => {
  const theme = useTheme()
  const image = getImage(data.clientImage)
  const logo = getImage(data.clientLogo)
  const templates = getImage(data.templates)

  return (
    <Grid>
      <Item>
        <GatsbyImage image={image} alt={data.clientImage.description} />
      </Item>
      <Item bgColor="#F4F4F4">
        <Box>
          <Title>
            <ClientIcon />
            &nbsp; Client
          </Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: data.clientText.childMarkdownRemark.html,
            }}
          />
        </Box>
      </Item>
      <Item>
        <Box>
          <Title>
            <ChallengeIcon />
            &nbsp; Challenge
          </Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: data.challengeText.childMarkdownRemark.html,
            }}
          />
        </Box>
      </Item>
      <Item
        bgColor={
          !data.darkQuote
            ? theme.palette.background.light
            : theme.palette.background.dark
        }
      >
        <Box>
          <Logo>
            <GatsbyImage image={logo} alt={data.clientLogo.description} />
          </Logo>
          <QuoteText darkQuote={data.darkQuote}>
            {data.quoteText.quoteText}
          </QuoteText>
          <QuoteSource>{data.quoteSource.quoteSource}</QuoteSource>
        </Box>
      </Item>
      <Item>
        <Box>
          <Title>
            <CustomizedTemplateIcon />
            &nbsp; Customized Templates
          </Title>
          <SubTitle>powered by Semplates</SubTitle>
          <Template>
            <GatsbyImage image={templates} alt={data.templates.description} />
          </Template>
        </Box>
      </Item>
      <Item>
        <Box>
          <Title>
            <SolutionIcon />
            &nbsp; Solution
          </Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: data.solutionText.childMarkdownRemark.html,
            }}
          />
        </Box>
      </Item>
    </Grid>
  )
}

export default CaseStudyMainSlice
