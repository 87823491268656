import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { media } from 'styled-bootstrap-grid'
import { Section } from '../Layout'

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  ${media.lg`
    flex-direction: row;
    width: 1000px;
    margin: 80px auto;
  `}
`

const Text = styled.p``

const TextContainer = styled.div`
  ${media.md`
    width: 600px;
    margin: auto;
  `}
  ${media.lg`
    width: 1000px;
    margin-left: 40px;
  `}
`

const ImageContainer = styled.div`
  width: 260px;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;
  ${media.lg`
      width: 200px;
      margin-left: 40px;
    `}
`

const TestimonialSlice = ({ data }) => {
  const image = getImage(data.testimonial.customerImage)
  return (
    <Section>
      <Flex>
        <ImageContainer>
          <GatsbyImage
            image={image}
            alt={data.testimonial.customerImage.description}
          />
        </ImageContainer>
        <TextContainer>
          <Text
            dangerouslySetInnerHTML={{
              __html: data.testimonial?.testimonial?.childMarkdownRemark?.html,
            }}
          />
          <p>
            <strong>{data.testimonial.customerDescription}</strong>
          </p>
        </TextContainer>
      </Flex>
    </Section>
  )
}

export default TestimonialSlice
