import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Section } from '../Layout'
import { getOutlink } from '../../utils/analyticsUtils'

const Label = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0.05em;
  text-align: left;
  margin: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const StyledButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 48px 0 4px 0;
  padding: 8px 16px;
  min-width: 104px;
  height: 32px;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.25s ease-in;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

const ButtonContainer = styled.div`
  width: fit-content;
`

const StyledHeader = styled.div`
  max-width: 1100px;
`

const StyledSubtitle = styled.p`
  max-width: 600px;
  text-align: left;
  margin-top: 20px;
`

const PageTitle = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: 40px;
  margin-bottom: 40px;
`

const CaseStudyHeaderSlice = ({ subTitle, data }) => (
  <Section bgStyle="dark">
    <StyledHeader>
      <PageTitle>Case Study</PageTitle>
      <h1>
        <span style={{ color: data.titleColor1 }}>{data.titlePart1}</span>
        &nbsp;
        <span style={{ color: data.titleColor2 }}>{data.titlePart2}</span>
      </h1>
      <StyledSubtitle>{subTitle}</StyledSubtitle>
      <ButtonContainer>
        <StyledButton to={getOutlink()}>
          <Label>Try it out yourself today</Label>
        </StyledButton>
      </ButtonContainer>
    </StyledHeader>
  </Section>
)

export default CaseStudyHeaderSlice
