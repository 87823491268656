import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import CaseStudyNumberCard from './CaseStudyNumberCard'

const Container = styled.section`
  max-width: 1400px;
  margin: auto;
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  ${media.lg`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  `}
`

const Item = styled.div`
  aspect-ratio: 1;
  background-color: ${({ bgColor }) => bgColor};
`

const CaseStudyNumberCardsSlice = ({ data }) => (
  <Container>
    <Grid>
      <Item>
        <CaseStudyNumberCard data={data.numberBox1} />
      </Item>
      <Item bgColor="#FFFCE9">
        <CaseStudyNumberCard data={data.numberBox2} />
      </Item>
      <Item bgColor="#FFF2B4">
        <CaseStudyNumberCard data={data.numberBox3} />
      </Item>
      <Item bgColor="#FFED99">
        <CaseStudyNumberCard data={data.numberBox4} />
      </Item>
    </Grid>
  </Container>
)

export default CaseStudyNumberCardsSlice
