import React from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 2fr;
  width: fit-content;
  margin-left: 32px;
  gap: 10px;
  ${media.sm`
    margin: auto;
    gap: 20px;
    grid-template-rows: 4fr 5fr;
  `}
`

const Box = styled.div`
  display: flex;
  align-items: end;
  max-width: fit-content;
`

const Number = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 4px;
  line-height: 0.8;
  font-size: 40px;
  ${media.md`
    font-size: 50px;
  `}
`

const NumberPrefix = styled(Number)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

const Text = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1;
  width: 70%;
  ${media.md`
    font-size: 22px;
    max-width: 260px;
  `}
`

const SubText = styled.div`
  font-size: 20px;
  max-width: 190px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  ${media.md`
    font-size: 28px;
  `}
`

const CaseStudyNumberCard = ({ data }) => (
  <Container>
    <Box>
      {data.numberPrefix && <NumberPrefix>{data.numberPrefix}</NumberPrefix>}
      {data.number && <Number>{data.number}</Number>}
      {data.numberSuffix && <Text>{data.numberSuffix}</Text>}
      &nbsp;
      {data.numberText && <Text>{data.numberText}</Text>}
    </Box>
    <SubText>{data.subText.subText}</SubText>
  </Container>
)

export default CaseStudyNumberCard
